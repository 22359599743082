import React from 'react';

function Demo() {
    const currentlyFundraising = [
        {
            name: 'Arithmic',
            oneLiner: 'Decentralized Universal L2 Enabling Multi-chain Native (re)Staking',
            presentationLink: 'https://docsend.com/view/4qu7scf6rzhmnd85/d/njk5skzjw7ws4zg6',
            pitchLink: 'https://docsend.com/view/4qu7scf6rzhmnd85/d/9yeb9eu9vipuftjh',
            email: 'karan@arithmic.com',
            hasMeetingLink: true,
            meetingLink: 'https://calendly.com/karan-bharadwaj/meeting-with-karan-arithmic'
        },
        {
            name: 'BIMA Labs',
            oneLiner: 'Bima is the universal Omni-chain stablecoin backed by Bitcoin. Whether it’s on EVM, Solana, STX, Bitcoin L1, Runes, Ordinals, etc - we go to the user wherever they live and enable them to unlock new financial utilities.',
            presentationLink: 'https://chestnut-cry-2e9.notion.site/Bima-6899445e8dd04bf3b1a81df70521a0d9',
            pitchLink: 'https://www.youtube.com/watch?v=sQRdKSH3LXA',
            email: 'sid@bima.money',
            hasMeetingLink: true,
            meetingLink: 'https://calendly.com/thesidsridhar',
        },
        {
            name: 'BitMind',
            oneLiner: 'Bitmind is a development platform designed to increase access and advance the development of open source and decentralized artificial intelligence (DeAI)',
            presentationLink: 'https://docsend.com/view/a3p6bmkyjcuk25u4',
            pitchLink: 'https://youtu.be/ikqd5lEUP2M',
            email: 'ken@bitmind.ca',
            hasMeetingLink: false,
        },
        {
            name: 'Concordia',
            oneLiner: 'The first AI-powered Memlake',
            presentationLink: 'https://papermark.io/view/clys9auku0001p1edvluublgj',
            pitchLink: 'https://www.loom.com/share/1645d4fd92334855863d60a723985e68',
            email: 'uri@concordia.systems',
            hasMeetingLink: false,
        },
        {
            name: 'HoneyCake',
            oneLiner: "Revolutionizing DeFi with the World's First Parallel Execution Hub",
            presentationLink: 'https://docsend.com/view/fjpif57ff3q4y2ez',
            pitchLink: 'https://www.loom.com/share/c60e4223c45e47dcad03e3806d83352c?sid=d6bcccc8-24d7-46f0-886a-853836ba1c03',
            email: 'contact@honeycake.xyz',
            hasMeetingLink: false,
        },
        {
            name: 'Pod',
            oneLiner: "Pod is a new layer-one primitive that relaxes the notion of total ordering transactions to achieve sub-second finality and giga-bit level throughput.",
            presentationLink: 'https://papermark.io/view/clysvps8d0003ibqut0ykijqf',
            pitchLink: 'https://www.loom.com/share/9243e45ee5834637b4310f26ace54a71?sid=32635ae1-d53b-4658-a064-29059db1606b',
            email: 'shresth@commonprefix.com',
            hasMeetingLink: false,
        },
        {
            name: 'SluiceBox AI',
            oneLiner: "Sluicebox AI is building the world’s largest proprietary dataset for risk and resilience on electronic components for the $2T value chain - starting with carbon.",
            presentationLink: 'https://docsend.com/view/hnvtnsjdwddn78p4/d/p7xmd5pp6p5rvjp3',
            pitchLink: 'https://docsend.com/view/hnvtnsjdwddn78p4/d/r6xaamef8ndgtfe6',
            email: 'elmar@sluicebox.ai',
            hasMeetingLink: false,
        },
        {
            name: 'Soul',
            oneLiner: "Soul is a Layer X+1 lending protocol built on top of leading lending protocols across multiple blockchains. It allows users to collateralize assets on any blockchain and borrow from a completely different chain.",
            presentationLink: 'https://docsend.com/view/s/pns3f8hi3cpz7bva',
            pitchLink: 'https://www.loom.com/share/c04e136a83f04f42b75ba2cb94574846?sid=aeb2cec9-8edf-4d2a-bf71-4a45bb748752',
            email: 'ceo@soul.io',
            hasMeetingLink: true,
            meetingLink: 'https://calendly.com/d/ck24-xfz-ctd/30minute',
        },

        {
            name: 'SphereOne',
            oneLiner: "The Skynet for Web3",
            presentationLink: 'https://docsend.com/view/y2e3zvfhnfjg44eh',
            pitchLink: 'https://www.loom.com/share/67f8355d1a3e4b2cb11e1a4ea6d277ca?sid=ec8995c1-462c-41a7-bdc5-d3dc02112a57',
            email: 'chris@sphereone.xyz',
            hasMeetingLink: true,
            meetingLink: 'https://calendly.com/d/g3j-6kj-myf/30-minute-meeting',
        },

    ];

    const fundraisingComplete = [
        { name: 'Akave', status: 'Fundraise Complete' },
        { name: 'Optimum', status: 'Oversubscribed' },
    ];

    const notYetFundraising = [
        { name: 'Momenta', status: 'Not yet fundraising' },
        { name: 'Skythorn', status: 'Still in stealth, fundraising soon' },
        { name: 'ZettaBlock', status: 'Still in stealth, fundraising soon' },
    ];

    const burntOrange = '#BF5700';
    const columnSpacing = 'px-4';

    return (
        <div className="p-12">
            <h1 className="text-4xl font-bold mb-6">Welcome to CSL Demo Day 2024</h1>
            <h2 className="text-2xl font-semibold mb-4">Currently Fundraising</h2>
            <div className="overflow-hidden rounded-lg shadow-md">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="text-left">
                            <th className={`py-2 ${columnSpacing} border-b w-1/6`}>Name</th>
                            <th className={`py-2 ${columnSpacing} border-b w-2/5`}>One-Liner</th>
                            <th className={`py-2 ${columnSpacing} border-b w-1/10`}>Brief Presentation</th>
                            <th className={`py-2 ${columnSpacing} border-b w-1/10`}>Elevator Pitch</th>
                            <th className={`py-2 ${columnSpacing} border-b w-1/10`}>Contact by Email</th>
                            <th className={`py-2 ${columnSpacing} border-b w-1/10`}>Book a Meeting</th>
                        </tr>
                    </thead>
                    <tbody className='text-sm'>
                        {currentlyFundraising.map((startup, index) => (
                            <tr key={index} className="h-24">
                                <td className={`py-2 ${columnSpacing} border-b`}>{startup.name}</td>
                                <td className={`py-2 ${columnSpacing} border-b overflow-hidden overflow-ellipsis`}>{startup.oneLiner}</td>
                                <td className={`py-2 ${columnSpacing} border-b`}>
                                    <a
                                        href={startup.presentationLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`px-2 py-3 text-white rounded-md hover:opacity-80 inline-block w-full text-center text-xs`}
                                        style={{ backgroundColor: burntOrange }}
                                    >
                                        Brief Presentation
                                    </a>
                                </td>
                                <td className={`py-2 ${columnSpacing} border-b`}>
                                    <a
                                        href={startup.pitchLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`px-2 py-3 text-white rounded-md hover:opacity-80 inline-block w-full text-center text-xs`}
                                        style={{ backgroundColor: burntOrange }}
                                    >
                                        Elevator Pitch
                                    </a>
                                </td>
                                <td className={`py-2 ${columnSpacing} border-b`}>
                                    <a
                                        href={`mailto:${startup.email}`}
                                        className={`px-2 py-3 text-white rounded-md hover:opacity-80 inline-block w-full text-center text-xs`}
                                        style={{ backgroundColor: burntOrange }}
                                    >
                                        {startup.email}
                                    </a>
                                </td>
                                <td className={`py-2 ${columnSpacing} border-b`}>
                                    {startup.hasMeetingLink ? (
                                        <a
                                            href={startup.meetingLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={`px-2 py-3 text-white rounded-md hover:opacity-80 inline-block w-full text-center text-xs`}
                                            style={{ backgroundColor: burntOrange }}
                                        >
                                            Book a Meeting
                                        </a>
                                    ) : (
                                        <button className="px-2 py-3 bg-gray-400 text-white rounded-md cursor-not-allowed w-full text-xs" disabled>
                                            No Meeting Link
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="mt-12 space-y-8">
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-2xl font-semibold mb-4 text-gray-800">Fundraising complete</h2>
                    <ul className="space-y-2">
                        {fundraisingComplete.map((startup, index) => (
                            <li key={index} className="flex items-center justify-between py-2 border-b border-gray-200 last:border-b-0">
                                <span className="font-medium text-gray-700">{startup.name}</span>
                                <span className={`px-3 py-1 rounded-full text-sm font-semibold ${startup.status === 'Oversubscribed'
                                    ? 'bg-orange-100 text-orange-800'
                                    : 'bg-green-100 text-green-800'
                                    }`}>
                                    {startup.status}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-2xl font-semibold mb-4 text-gray-800">Not yet fundraising</h2>
                    <ul className="space-y-2">
                        {notYetFundraising.map((startup, index) => (
                            <li key={index} className="flex items-center justify-between py-2 border-b border-gray-200 last:border-b-0">
                                <span className="font-medium text-gray-700">{startup.name}</span>
                                <span className={`px-3 py-1 rounded-full text-sm font-semibold ${startup.status === 'Still in stealth, fundraising soon'
                                    ? 'bg-blue-100 text-blue-800'
                                    : 'bg-purple-200 text-purple-800'
                                    }`}>
                                    {startup.status}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        </div>
    );

}

export default Demo;

