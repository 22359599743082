import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import React from "react"
import Home from "./Home"
import Demo from "./Demo"


function App() {
  return (
    <div>
      <Router>
        <Routes>

          <Route exact path="/" element={<Home />} />
          <Route path="/demo" element={<Demo />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
