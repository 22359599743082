import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function Home() {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            to_email: email,
            message: 'Thank you for your interest in the CSL 2024 Demo Day!\n\nBelow is the link to access the demos:\nhttps://csl-demo-day.webflow.io/csl-demo-day-main',
        };

        emailjs.send('service_p49iibg', 'template_ig4hbdm', templateParams, 'Y2J45rpnmxpwYz2e_')
            .then((response) => {
                console.log('Email successfully sent!', response.status, response.text);
                setSubmitted(true);
            })
            .catch((err) => {
                console.error('Failed to send email:', err);
            });

        setEmail('');
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <img src="demodaylogo.png" alt="CSL 2024 Demo Day" className="w-48 h-48 mb-10" />
            <div className="text-4xl font-bold mb-4">CSL 2024 Demo Day</div>
            <p className='mb-5'>To view all projects, please enter email below</p>
            {submitted ? (
                <div className="text-lg text-green-500">Email Sent!</div>
            ) : (
                <form onSubmit={handleSubmit} className="flex flex-row items-center">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="px-4 py-2 mr-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    <button
                        type="submit"
                        className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
}

export default Home;
